import React from 'react';

export const instagramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#FAFAFA"
        fillRule="evenodd"
        d="M7.749 1.799a5.952 5.952 0 00-5.952 5.95V23.62a5.952 5.952 0 005.952 5.952H23.62a5.952 5.952 0 005.95-5.953V7.75a5.952 5.952 0 00-5.95-5.95H7.749zm17.584 5.959a1.714 1.714 0 11-3.429 0 1.714 1.714 0 013.429 0zm-9.646 3.172a4.754 4.754 0 100 9.509 4.754 4.754 0 000-9.509zm-7.042 4.755a7.04 7.04 0 1114.08 0 7.04 7.04 0 01-14.08 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
