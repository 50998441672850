import React from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { IconInfoItems, NewsletterForm } from '../../../../components';
import { FormattedMessage, injectIntl } from '../../../../util/reactIntl';
import Field from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const {
    sectionId,
    className,
    rootClassName,
    numberOfColumns,
    socialMediaLinks,
    slogan,
    appearance,
    copyright,
    blocks,
    options,
    intl,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const linksWithBlockId = socialMediaLinks?.map(sml => {
    return {
      ...sml,
      blockId: sml.link.platform,
    };
  });

  const showSocialMediaLinks = socialMediaLinks?.length > 0;
  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;
  const logoLayout = isMobileLayout ? 'mobile' : 'desktop';

  // use block builder instead of mapping blocks manually

  // const footerLinks = [
  //   {
  //     icon: 'ironcladGuarantee',
  //     title: intl.formatMessage({ id: 'SectionFooter.ironcladGuaranteeTitle' }, { lineBreak: <br /> }),
  //     link: intl.formatMessage({ id: 'SectionFooter.ironcladGuaranteeLink' }),
  //     linkText: intl.formatMessage({ id: 'SectionFooter.ironcladGuaranteeLinkText' }),
  //   },
  //   {
  //     icon: 'footprint',
  //     title: intl.formatMessage({ id: 'SectionFooter.footprintTitle' }, { lineBreak: <br /> }),
  //     link: intl.formatMessage({ id: 'SectionFooter.footprintLink' }),
  //     linkText: intl.formatMessage({ id: 'SectionFooter.footprintLinkText' }),
  //   },
  //   {
  //     icon: 'patagoniaActionWorks',
  //     title: intl.formatMessage({ id: 'SectionFooter.patagoniaActionWorksTitle' }, { lineBreak: <br /> }),
  //     link: intl.formatMessage({ id: 'SectionFooter.patagoniaActionWorksLink' }),
  //     linkText: intl.formatMessage({ id: 'SectionFooter.patagoniaActionWorksLinkText' }),
  //   },
  //   {
  //     icon: 'wornWear',
  //     title: intl.formatMessage({ id: 'SectionFooter.wornWearTitle' }, { lineBreak: <br /> }),
  //     link: intl.formatMessage({ id: 'SectionFooter.wornWearLink' }),
  //     linkText: intl.formatMessage({ id: 'SectionFooter.wornWearLinkText' }),
  //   },
  //   {
  //     icon: 'commitment',
  //     title: intl.formatMessage({ id: 'SectionFooter.commitmentTitle' }, { lineBreak: <br /> }),
  //     link: intl.formatMessage({ id: 'SectionFooter.commitmentLink' }),
  //     linkText: intl.formatMessage({ id: 'SectionFooter.commitmentLinkText' }),
  //   },
  // ];

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={className || css.root}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.footer}>
        {/* <ul className={css.footerList}>
          {footerLinks.map(l => {
            return (
              <li key={l.icon} className={css.footerListItem}>
                <IconInfoItems type={l.icon} />
                <h3 className={css.footerListItemTitle}>{l.title}</h3>
                <a className={css.footerListItemLink} href={l.link} target="_blank">{l.linkText}</a>
              </li>
            )
          })}
        </ul> */}

        <div className={css.formAndLinks}>
          <div className={css.form}>
            <NewsletterForm onSubmit={() => { }} />
            <div className={css.copyAndSocial}>
              {showSocialMediaLinks ? (
                <div className={css.icons}>
                  <BlockBuilder blocks={linksWithBlockId} sectionId={sectionId} options={options} />
                </div>
              ) : null}
            </div>
            <Field data={copyright} className={css.copyright} />
          </div>
          <div className={css.links}>
            <BlockBuilder blocks={blocks} sectionId={sectionId} options={options} />
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default injectIntl(SectionFooter);
